<template>
  <v-container
    class="content mt-0 pa-0 elevation-0"
    fluid
  >
    <v-card
      class="elevation-0 mx-0 border-bottom border-left border-right"
      color="basil"
    >
      <v-tabs
        v-model="showType"
        background-color="transparent"
        grow
      >
        <v-tab>
          <template>
            <font-awesome-icon
              :icon="['fal', 'list']"
              class="text-muted actions"
              size="2x"
              style="font-size: 16px"
            />
            <span class="pl-2">{{ $t('generic.lang_list') }}</span>
          </template>
        </v-tab>
        <v-tab>
          <template>
            <font-awesome-icon
              :icon="['fal', 'calendar']"
              class="text-muted actions"
              size="2x"
              style="font-size: 16px"
            />
            <span class="pl-2">{{ $t('generic.lang_calendar') }}</span>
          </template>
        </v-tab>
      </v-tabs>

      <v-tabs-items
        class="py-3"
        v-model="showType"
      >
        <v-tab-item>
          <orders-overview-list></orders-overview-list>
        </v-tab-item>
        <v-tab-item>
          <orders-overview-calendar></orders-overview-calendar>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faList } from '@fortawesome/pro-light-svg-icons';
library.add(faCalendar, faList);

import OrdersOverviewList from '@/components/eventbee/order-overview/OrdersOverviewList';
import OrdersOverviewCalendar from '@/components/eventbee/order-overview/OrdersOverviewCalendar.vue';
export default {
  name: 'OrdersOverviewComponent',
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    OrdersOverviewList,
    OrdersOverviewCalendar,
  },
  data() {
    return {
      showType: 0,
    };
  },
  methods: {},
  mounted() {},
};
</script>
