<template>
  <v-container
    class="content mt-0 pa-0 elevation-0"
    fluid
  >
    <v-row
      align="center"
      class="mx-auto elevation-0"
    >
      <v-col
        col="12"
        class="m-0 p-0 white mt-2"
      >
        <v-row class="ma-0">
          <v-col
            cols="12"
            lg="4"
            md="6"
            sm="12"
          >
            <b-form-select
              :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
              v-model="form.type"
              :label-field="$t('generic.lang_allTypes')"
              :placeholder="$t('generic.lang_allTypes')"
              size="md"
            >
              <b-form-select-option :value="-1">{{
                $t('generic.lang_allTypes')
              }}</b-form-select-option>
              <b-form-select-option :value="0">{{
                $t('eventbee.lang_entryTicket')
              }}</b-form-select-option>
              <b-form-select-option :value="1">{{
                $t('eventbee.lang_eventTicket')
              }}</b-form-select-option>
            </b-form-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-select
              :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
              v-model="form.groupID"
              :label-field="$t('eventbee.lang_eventGroup')"
              :placeholder="$t('eventbee.lang_eventGroup')"
              size="md"
            >
              <b-form-select-option :value="-1"
                >{{
                  $t('generic.lang_all') + ' ' + $t('eventbee.lang_eventGroups')
                }}
              </b-form-select-option>
              <b-form-select-option
                v-for="t in this.eventGroups"
                :key="t.id"
                :value="t.id"
              >
                {{ t.name }}
              </b-form-select-option>
            </b-form-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-select
              :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
              v-model="form.providerID"
              :placeholder="$t('generic.lang_provider')"
              se
              size="md"
            >
              <b-form-select-option :value="-1"
                >{{
                  $t('generic.lang_all') + ' ' + $t('generic.lang_provider')
                }}
              </b-form-select-option>
              <b-form-select-option
                v-for="t in this.serviceProviders"
                :key="t.userID"
                :value="t.userID"
              >
                {{ t.fullName }}
              </b-form-select-option>
            </b-form-select>
          </v-col>

          <v-col
            cols="12"
            class="p-0 text-center align-center"
          >
            <v-tabs
                v-model="selectedMonthIndex"
                :value="selectedMonthIndex"
                @change="changeMonth"
                center-active
                show-arrows
            >
              <v-tab
                  v-for="(month, index) in yearMonths"
                  :key="index"
                  active-class="primary text-white"
                  class="border mx-0 text-center"
              >
                <div>
                  <strong>{{ month.label }}</strong><br/>
                  <small>{{ month.year }}</small>
                </div>
              </v-tab>
            </v-tabs>
          </v-col>
          <!-- <v-col
            cols="12"
            md="2"
            class="border-top d-flex align-center justify-end py-0"
          >
            <v-btn
              class="mr-0 mt-0"
              color="primary"
              depressed
              large
              outlined
            >
              <v-icon> mdi-calendar </v-icon>
              <strong class="ml-2">
                {{
                  capitalize(
                    this.$moment(this.form.startDate, 'DD.MM.YYYY').format(
                      'YYYY'
                    )
                  )
                }}
              </strong>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>

    <!-- start: orders -->
    <v-row class="mx-0">
      <v-col cols="12">
        <v-progress-linear
          v-if="this.loadingOrders"
          indeterminate
          color="primary"
          class="mx-auto ma-0 p-0"
          infinite
        ></v-progress-linear>
        <div>
          <v-sheet>
            <v-calendar
              ref="calendar"
              id="event-bee-orders-overview-calendar"
              :weekdays="weekday"
              :loading="loadingOrders"
              :type="type"
              :events="this.orders"
              :event-overlap-threshold="30"
              @click:event="setOrder"
              :event-color="getEventColor"
              :value="this.calendarDate"
              :now="this.calendarDate"
              :event-more="false"
            >
              <template v-slot:event="{ event }">
                <div class="p-1">
                  <template v-if="customerFullName(event)">
                    <v-icon
                        small
                        color="white"
                    >
                      mdi-account
                    </v-icon>
                    <strong class="ml-1">{{ customerFullName(event) }}</strong>
                  </template>
                  <br />
                  <v-icon
                    small
                    color="white"
                  >
                    mdi-ticket
                  </v-icon>
                  <strong class="ml-1">{{ event.name }}</strong>
                  <br />

                  <v-icon
                    color="white"
                    small
                  >
                    mdi-account-multiple
                  </v-icon>
                  <span class="font-weight-bolder ml-1 mt-1"
                    >{{ event.order.numberOfPersons }}
                  </span>
                  <span class="pt-1"> {{ $t('generic.lang_persons') }}</span>
                  <br />
                  <v-icon
                    color="white"
                    small
                  >
                    mdi-calendar
                  </v-icon>
                  <span class="font-weight-bolder ml-1 mt-1"
                    >{{ guestExpectedAfterOrBefore(event) }}
                  </span>
                  <div v-if="event.order.slotFrom || event.order.slotTo || event.order.arrival">
                    <v-icon
                        color="white"
                        small
                    >
                      mdi-clock-outline
                    </v-icon>
                    <span v-if="event.order.arrival" class="text--black ml-1 font-weight-bold">
                  {{ event.order.arrival }}
                </span>
                    <span v-else class="text--black ml-1 font-weight-bold">{{
                        (event.order.slotFrom?event.order.slotFrom:'') + ' ' +(event.order.slotTo?event.order.slotTo:'')
                      }}</span>
                  </div>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <EventbeeOrderDetailsComponent v-model="showOrderDetailsDialog" :uuid="selectedOrderUUID" @close="selectedOrderUUID=''" @updateOrders="getOrders"/>
  </v-container>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import { capitalize } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faList,
  faUserAlt,
  faCalendarDay,
} from '@fortawesome/pro-light-svg-icons';
import EventbeeOrderDetailsComponent from "./EventbeeOrderDetailsComponent";
import {mapGetters} from "vuex";
library.add(faCalendar, faList, faUserAlt, faCalendarDay);
export default {
  name: 'OrdersOverviewCalendar',
  components: {
    EventbeeOrderDetailsComponent,
    'font-awesome-icon': FontAwesomeIcon,
  },
  data() {
    return {
      showType: 0,
      showOrderDetailsDialog:false,
      selectedOrderUUID:null,
      eventGroups: [],
      serviceProviders: [],
      orders: [],
      loadingOrders: false,
      selectedMonthIndex: 12 + this.$moment().startOf('month').month(),
      selectedDate: '',
      form: {
        type: -1,
        groupID: -1,
        providerID: -1,
        // first day of the month
        startDate: this.$moment()
          .startOf('month')
          .format('DD.MM.YYYY')
          .toLowerCase(),
        // last day of the month
        endDate: this.$moment()
          .endOf('month')
          .format('DD.MM.YYYY')
          .toLowerCase(),
      },
      weekday: [0, 1, 2, 3, 4, 5, 6],
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
    };
  },
  watch: {
    form: {
      handler() {
        this.getOrders();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      currentLanguage:'app/currentLanguage'
    }),
    guestExpectedAfterOrBefore() {
      return (order) => {
        if (
          order.event?.eventType === 0 &&
          order?.order?.slotFrom &&
          order?.order?.slotTo
        ) {
          const fullDate = this.$moment(
            `${order?.order?.date} ${order.order.slotFrom}`,
            'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        } else if (order.event?.eventType === 0 && order?.order?.arrival) {
          const fullDate = this.$moment(
            `${order?.order?.date} ${order.order.arrival}`,
            'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        } else {
          const fullDate = this.$moment(
            `${order?.order?.date} ${order.order.fromTime}`,
            'DD.MM.YYYY HH:mm'
          );

          return this.$moment(fullDate).fromNow();
        }
      };
    },
    calendarDate() {
      return this.$moment(this.form.startDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      );
    },
    yearMonths() {
      let months = [];
      let start = this.$moment().subtract(1,'year').startOf('year');
      let end = this.$moment().add(1,'year').endOf('year');
      while (start.isBefore(end)) {
        months.push({
          label: start.format('MMMM'),
          start: start.startOf('month').format('DD.MM.YYYY'),
          end: start.endOf('month').format('DD.MM.YYYY'),
          year: start.format('YYYY'),
        });
        start.add(1, 'month');
      }
      return months;
    },
    customerFullName(){
      return event => {
        if(event.order && event.order.customerData){
          return event.order.customerData.first_name+' '+event.order.customerData.last_name;
        }
        return null;
      }
    }
  },
  methods: {
    capitalize,
    setOrder(order){
      this.selectedOrderUUID = order.event.order.uuid;
      this.showOrderDetailsDialog = true;
    },
    changeMonth(monthIndex) {
      this.form.startDate = this.yearMonths[monthIndex].start;
      this.form.endDate = this.yearMonths[monthIndex].end;
    },
    getEventGroups() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {})
        .then((res) => {
          this.eventGroups = res.data.data;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    getServiceProviders() {
      this.loadingGroups = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SERVICEPROVIDERS, {})
        .then((res) => {
          this.serviceProviders = res.data.users;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },

    getOrders() {
      this.loadingOrders = true;
      const _form = { ...this.form };
      _form.startDate = this.$moment(_form.startDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      );
      _form.endDate = this.$moment(_form.endDate, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      );

      this.axios
        .post(ENDPOINTS.EVENTBEE.ORDERS.OVERVIEW.GET, _form)
        .then((res) => {
          if (res.data.status === 'success') {
            this.orders = res.data.data;

            this.orders = res.data.data?.map((order) => {
              const _order = structuredClone(order);
              const event = {};

              if (
                _order.event?.eventType === 0 &&
                _order?.order?.slotFrom &&
                _order?.order?.slotTo
              ) {
                const fullDate = this.$moment(
                  `${_order?.order?.date} ${_order.order.slotFrom}`,
                  'DD.MM.YYYY HH:mm'
                );

                event.start = this.$moment(fullDate).toDate();
                event.end = this.$moment(
                  `${_order?.order?.date} ${_order.order.slotTo}`,
                  'DD.MM.YYYY HH:mm'
                ).toDate();
              } else if (
                _order.event?.eventType === 0 &&
                _order?.order?.arrival
              ) {
                event.start = this.$moment(
                  `${_order?.order?.date} ${_order.order.arrival}`,
                  'DD.MM.YYYY HH:mm'
                ).toDate();

                event.end = this.$moment(
                  `${_order?.order?.date} ${_order.order.arrival}`,
                  'DD.MM.YYYY HH:mm'
                )
                  .add(120, 'minutes')
                  .toDate();
              } else {
                // the event is available for the whole day
                event.start = this.$moment(
                  `${_order?.order?.date} ${_order.order.fromTime}`,
                  'DD.MM.YYYY HH:mm'
                ).toDate();
                event.end = this.$moment(
                  `${_order?.order?.date} ${_order.order.toTime}`,
                  'DD.MM.YYYY HH:mm'
                ).toDate();
              }

              event.color =
                _order.eventPlan?.color ??
                (this.$moment(event.start).isBefore(this.$moment())
                  ? 'error'
                  : 'success');
              /* this.$moment(event.start).isBefore(this.$moment())
                ? 'error'
                : 'success'; */

              event.timed = true;
              event.name = _order.order.eventName;

              return {
                ..._order,
                ...event,
              };
            });
          } else {
            throw new Error(this.$t('generic.lang_errorOccurred'));
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loadingOrders = false;
        });
    },

    getEventColor(event) {
      return event.color;
    },
  },
  mounted() {
    this.getEventGroups();
    this.getServiceProviders();
  },
};
</script>

<style>
.month-btn .v-btn__content {
  display: block !important;
}

.month-btn {
  width: calc(100% / 12) !important;
}

.months-slider .v-slide-group__content {
  display: block !important;
  display: -webkit-block !important;
  display: -ms-block !important;
}

#event-bee-orders-overview-calendar .v-event-start,
#event-bee-orders-overview-calendar .v-event {
  white-space: unset !important;
  text-overflow: unset !important;
  height: auto !important;
}
</style>
