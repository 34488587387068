<template>
  <div>
    <page-title
      :heading="'EventBee'"
      :icon="icon"
      :subheading="$t('generic.lang_overview')"
    ></page-title>
    <div class="app-main__inner">
      <orders-overview-component></orders-overview-component>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/Layout/Components/PageTitle';
import OrdersOverviewComponent from '@/components/eventbee/order-overview/OrdersOverviewComponent';
export default {
  name: 'EventOrders',
  components: { PageTitle, OrdersOverviewComponent },
  data: () => ({
    icon: 'pe-7s-ticket icon-gradient bg-tempting-azure',
  }),
};
</script>

<style scoped></style>
